import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import { GetApi } from "../../../utils/GetApi";
import AllEmployeeEditPopup from "../../../components/modelpopup/AllEmployeeEditPopup";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const EmployeeList = () => {
  const [empList, setEmpList] = useState([]);
  const [editData, setEditData] = useState({});
  const [searchData, setSearchdata] = useState({});
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    getEmployeeList();
  }, [searchData]);

  const getEmployeeList = async () => {
    await GetApi(
      "employee",
      "empCompanyId",
      localStorage.getItem("userId")
    ).then((res) => {
      const newData = res.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      newData.sort((a, b) => a.empId.localeCompare(b.empId));

      setEmpList(newData);

      if (Object.keys(searchData).length > 0) {
        handleFilterEmpList(newData);
      }
    });
  };

  const handleFilterEmpList = (newData) => {
    if (
      searchData.empId !== "" ||
      searchData.empName !== "" ||
      searchData.empPhone !== ""
    ) {
      if (searchData.empId !== "") {
        const list = newData.filter((item) => item.empId === searchData.empId);
        setEmpList(list);
      }
      if (searchData.empName !== "") {
        const list = newData.filter((item) => item.name === searchData.empName);
        setEmpList(list);
      }
      if (searchData.empPhone !== "") {
        const list = newData.filter(
          (item) => item.phone === searchData.empPhone
        );
        setEmpList(list);
      }
      if (searchData.empPhone !== "" && searchData.empId !== "") {
        const list = newData.filter((item) => item.empId === searchData.empId);
        setEmpList(list);
      }
      if (searchData.empPhone !== "" && searchData.empName !== "") {
        const list = newData.filter(
          (item) => item.phone === searchData.empPhone
        );
        setEmpList(list);
      }
      if (searchData.empId !== "" && searchData.empName !== "") {
        const list = newData.filter((item) => item.empId === searchData.empId);
        setEmpList(list);
      }
    } else {
      setEmpList([]);
      getEmployeeList();
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="/profile" state={record} className="avatar">
            <img alt="" src={record.empImage} />
          </Link>
          <Link to="/profile" state={record}>
            <span>{record.name}</span>
          </Link>
        </span>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Employee ID",
      dataIndex: "empId",
      sorter: (a, b) => a.empId.localeCompare(b.empId),
    },

    {
      title: "Mobile",
      dataIndex: "phone",
    },

    {
      title: "Join Date",
      dataIndex: "empJoinDate",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="">
            <span>{record.empJoinDate.slice(0, 16)}</span>
          </Link>
        </span>
      ),
      sorter: (a, b) => new Date(a.empJoinDate) - new Date(b.empJoinDate),
    },
    {
      title: "Department",
      render: (i, record) => <span>{record.empDepartmentName}</span>,
      sorter: (a, b) => a.empDepartmentName.localeCompare(b.empDepartmentName),
    },
    {
      title: "Designation",
      render: (i, record) => <span>{record.empDesignationName}</span>,
      sorter: (a, b) =>
        a.empDesignationName.localeCompare(b.empDesignationName),
    },
    {
      title: "Shift",
      render: (i, record) => <span>{record.shiftName}</span>,
    },
    {
      title: "Shift Start Date",
      render: (i, record) => <span>{record.shiftEffectiveDate}</span>,
    },
    {
      title: "Action",
      render: (i, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_employee"
              onClick={() => setEditData(record)}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => setDeleteId(record.id)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(empList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(dataBlob, `${localStorage.getItem("userName")}-employeedata.xlsx`);
  };

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
          />
          {/* /Page Header */}

          <EmployeeListFilter
            setSearchdata={setSearchdata}
            handleClick={handleExport}
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={empList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <AllEmployeeAddPopup empLength={empList?.length} />

        <AllEmployeeEditPopup data={editData} />

        <DeleteModal
          Name="Delete Employee"
          deleteId={deleteId}
          docName="employee"
        />
      </div>
    </div>
  );
};

export default EmployeeList;
