import React, { useEffect, useState } from "react";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
} from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import { GetApi } from "../../../utils/GetApi";
import AllEmployeeEditPopup from "../../../components/modelpopup/AllEmployeeEditPopup";
import EmployeeAttendenceFilter from "../../../components/EmployeeAttendenceFilter";

const AllEmployeeListAttendence = () => {
  const [empList, setEmpList] = useState([]);
  const [attendenceData, setAttendenceData] = useState([]);
  const [searchData, setSearchdata] = useState({});
  const [generalParameters, setGeneralParameters] = useState([]);
  useEffect(() => {
    getEmployeeList();
  }, [searchData]);

  useEffect(() => {
    getCompanyGeneralData();
  }, []);

  const getEmployeeList = async () => {
    await GetApi(
      "empattendence",
      "companyId",
      localStorage.getItem("userId")
    ).then((res) => {
      const newData = res.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let sortedarr = newData.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });

      setAttendenceData(sortedarr);

      const uniqueData = sortedarr.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.date.slice(0, 15) === item?.date.slice(0, 15) &&
              t.empId === item.empId
          )
      );

      setEmpList(uniqueData);

      if (Object.keys(searchData).length > 0) {
        handleFilterEmpList(newData);
      }
    });
  };

  const getCompanyGeneralData = async () => {
    await GetApi(
      "generalparameters",
      "companyId",
      localStorage.getItem("userId")
    )
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        // console.log("newdata", newData);
        setGeneralParameters(newData);
      })
      .catch((err) => {});
  };

  const handleFilterEmpList = (newData) => {
    if (
      searchData.empId !== "" ||
      searchData.empName !== "" ||
      searchData.empDate !== ""
    ) {
      if (searchData.empId !== "") {
        const list = newData.filter(
          (item) =>
            item.empId?.toLowerCase() === searchData.empId?.toLowerCase()
        );
        setEmpList(list);
      }
      if (searchData.empName !== "") {
        const list = newData.filter(
          (item) =>
            item.name?.toLowerCase() === searchData.empName?.toLowerCase()
        );
        setEmpList(list);
      }
      if (searchData.empDate !== "") {
        const list = newData.filter(
          (item) => item.date?.slice(0, 15) === searchData.empDate?.slice(0, 15)
        );
        setEmpList(list);
      }
      if (searchData.empDate !== "" && searchData.empId !== "") {
        const list = newData.filter(
          (item) =>
            item.empId?.toLowerCase() === searchData.empId?.toLowerCase() &&
            item.date?.slice(0, 15) === searchData.empDate?.slice(0, 15)
        );
        setEmpList(list);
      }
      if (searchData.empDate !== "" && searchData.empName !== "") {
        const list = newData.filter(
          (item) =>
            item.name?.toLowerCase() === searchData.empName?.toLowerCase() &&
            item.date?.slice(0, 15) === searchData.empDate?.slice(0, 15)
        );
        setEmpList(list);
      }
      if (searchData.empId !== "" && searchData.empName !== "") {
        const list = newData.filter(
          (item) =>
            item.empId?.toLowerCase() === searchData.empId?.toLowerCase()
        );
        setEmpList(list);
      }
    } else {
      setEmpList([]);
      getEmployeeList();
    }
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Employee ID",
      dataIndex: "empId",
      sorter: (a, b) => a.empId.localeCompare(b.empId),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => <p>{record?.date?.slice(0, 15)}</p>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "PunchIn",
      dataIndex: "punchIn",

      // sorter: (a, b) => a.punchIn.length - b.punchIn.length,
      render: (text, record) =>
        attendenceData?.filter(
          (item) =>
            item?.punchIn &&
            item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
            item.empId === record.empId
        )[0]?.punchIn,
    },
    {
      title: "PunchOut",
      dataIndex: "punchOut",

      // sorter: (a, b) => a.punchOut.length - b.punchOut.length,
      render: (text, record) =>
        attendenceData?.filter(
          (item) =>
            item?.punchOut &&
            item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
            item.empId === record.empId
        )[0]?.punchOut,
    },
    {
      title: "Traveling",
      dataIndex: "isTraveling",
      render: (text, record) => (
        <p>{record?.isTraveling === true ? "Yes" : "No"}</p>
      ),
    },

    {
      title: "Latitude",
      dataIndex: "lat",
      // sorter: (a, b) => a.lat.length - b.lat.length,
    },
    {
      title: "Longitude",
      dataIndex: "long",
      // sorter: (a, b) => a.long.length - b.long.length,
    },
    {
      title: "Attendence",
      dataIndex: "",
      // sorter: (a, b) => a.long.length - b.long.length,
      render: (text, record) => {
        let punchIn = attendenceData
          ?.filter(
            (item) =>
              item?.punchIn &&
              item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
              item?.empId === record?.empId
          )
          .map((it) => it?.date);

        let punchOut = attendenceData
          ?.filter(
            (item) =>
              item?.punchOut &&
              item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
              item?.empId === record?.empId
          )
          .map((it) => it?.date);

        const timeDifferences = punchIn?.map((obj1) => {
          const obj2 = punchOut?.find((obj) => obj);
          if (obj2) {
            const timeDiff = new Date(obj2) - new Date(obj1);
            const diffInSeconds = Math.floor(timeDiff / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = diffInMinutes / 60;
            return { timeDifference: diffInHours };
          }
          return null;
        });

        return (
          <p>
            {timeDifferences[timeDifferences?.length - 1] !== null &&
            timeDifferences[timeDifferences?.length - 1]?.timeDifference !==
              undefined
              ? `${timeDifferences[
                  timeDifferences?.length - 1
                ]?.timeDifference?.toFixed(2)} Hrs`
              : "NA"}
            {timeDifferences[timeDifferences?.length - 1] !== null &&
            timeDifferences[timeDifferences?.length - 1]?.timeDifference !==
              undefined
              ? timeDifferences[timeDifferences?.length - 1]?.timeDifference >
                parseInt(generalParameters[0]?.defaultDeutyHrs) -
                  parseInt(generalParameters[0]?.grasePreiodDuety) / 60
                ? " (P)"
                : " (A)"
              : ""}
          </p>
        );
      },
    },
  ];


  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee Attendence"
            title="Dashboard"
            subtitle="Employee Attendence"
            Linkname="/employees"
            Linkname1="/employees-list"
          />
          {/* /Page Header */}
          <EmployeeAttendenceFilter setSearchdata={setSearchdata} />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  size="small"
                  dataSource={empList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};

export default AllEmployeeListAttendence;
