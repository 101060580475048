import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AttendanceEmployeeFilter from "../../../components/AttendanceEmployeeFilter";
import { base_url } from "../../../base_urls";
import { UpdateApi } from "../../../utils/UpdateApi";
import { CreateApi } from "../../../utils/PostApi";
import DatePicker from "react-datepicker";

import {
  GetApi,
  getBranchDetail,
  getGeneralParameterDetail,
} from "../../../utils/GetApi";
import useGeolocation from "react-hook-geolocation";
import { Link } from "react-router-dom";

const AttendanceEmployee = () => {
  var time = new Date();
  const geolocation = useGeolocation();

  const [minDistance, setMinDistance] = useState(0);
  const [companyData, setCompanyData] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [punchIn, setPunchIn] = useState(false);
  const [punchIndata, setPunchIndata] = useState([]);
  const [punchOutdata, setPunchOutdata] = useState([]);
  const [attendenceData, setAttenedenceData] = useState([]);
  const [attendenceList, setAttendenceList] = useState([]);
  const [todayactivity, setTodayActivity] = useState([]);
  const [isTraveling, setIsTraveling] = useState(false);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [alreadyPunchIn, setAlreadyPunchIn] = useState(false);
  const [alreadyPunchOut, setAlreadyPunchOut] = useState(true);
  const [generalParameters, setGeneralParameters] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisible1, setModalVisible1] = useState(false);
  const [selectedTime, setSelectedTime] = useState("10:00");
  const userElements = data?.map((user, index) => ({
    key: index,
    id: user.id,
    Date: user.Date,
    PunchIn: user.PunchIn,
    PunchOut: user.PunchOut,
    Production: user.Production,
    Break: user.Break,
    Overtime: user.Overtime,
  }));

  const columns = [
    // {
    //   title: "Employee Name",
    //   dataIndex: "name",
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    // },
    {
      title: "Employee ID",
      dataIndex: "empId",
      sorter: (a, b) => a.empId.localeCompare(b.empId),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => <p>{record?.date?.slice(0, 15)}</p>,
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "PunchIn",
      dataIndex: "punchIn",

      // sorter: (a, b) => a.punchIn.length - b.punchIn.length,
      render: (text, record) =>
        attendenceData?.filter(
          (item) =>
            item?.punchIn &&
            item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
            item.empId === record.empId
        )[0]?.punchIn,
    },
    {
      title: "PunchOut",
      dataIndex: "punchOut",

      // sorter: (a, b) => a.punchOut.length - b.punchOut.length,
      render: (text, record) =>
        attendenceData?.filter(
          (item) =>
            item?.punchOut &&
            item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
            item.empId === record.empId
        )[0]?.punchOut,
    },
    {
      title: "Traveling",
      dataIndex: "isTraveling",
      render: (text, record) => (
        <p>{record?.isTraveling === true ? "Yes" : "No"}</p>
      ),
    },

    {
      title: "Latitude",
      dataIndex: "lat",
      // sorter: (a, b) => a.lat.length - b.lat.length,
    },
    {
      title: "Longitude",
      dataIndex: "long",
      // sorter: (a, b) => a.long.length - b.long.length,
    },
    {
      title: "Attendence",
      dataIndex: "",
      // sorter: (a, b) => a.long.length - b.long.length,
      render: (text, record) => {
        let punchIn = attendenceData
          ?.filter(
            (item) =>
              item?.punchIn &&
              item?.date?.slice(0, 15) === record?.date?.slice(0, 15)
          )
          .map((it) => it?.date);

        let punchOut = attendenceData
          ?.filter(
            (item) =>
              item?.punchOut &&
              item?.date?.slice(0, 15) === record?.date?.slice(0, 15)
          )
          .map((it) => it?.date);

        const timeDifferences = punchIn.map((obj1) => {
          const obj2 = punchOut.find((obj) => obj);
          if (obj2) {
            const timeDiff = new Date(obj2) - new Date(obj1);
            const diffInSeconds = Math.floor(timeDiff / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = diffInMinutes / 60;
            return { timeDifference: diffInHours };
          }
          return null;
        });

        return (
          <p>
            {timeDifferences[timeDifferences?.length - 1] !== null &&
            timeDifferences[timeDifferences?.length - 1]?.timeDifference !==
              undefined
              ? `${timeDifferences[
                  timeDifferences?.length - 1
                ]?.timeDifference?.toFixed(2)} Hrs`
              : "NA"}
            {timeDifferences[timeDifferences?.length - 1] !== null &&
            timeDifferences[timeDifferences?.length - 1]?.timeDifference !==
              undefined
              ? timeDifferences[timeDifferences?.length - 1]?.timeDifference >
                parseInt(generalParameters[0]?.defaultDeutyHrs) -
                  parseInt(generalParameters[0]?.grasePreiodDuety) / 60
                ? " (P)"
                : " (A)"
              : ""}
          </p>
        );
      },
    },
  ];

  let previousDate = new Date();
  previousDate.setDate(previousDate.getDate() - 1);

  const handleMarkAttendence = async (status) => {
    if (status === "punchOut1") {
      const timeDiff = new Date() - new Date(todayactivity[0]?.date);
      const diffInSeconds = Math.floor(timeDiff / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = diffInMinutes / 60;

      if (diffInHours < parseInt(generalParameters[0]?.defaultDeutyHrs)) {
        setModalVisible(true);
      } else {
        setModalVisible(false);
        markAttendence(status);
      }
    }

    if (status === "punchIn1") {
      let filteredData = attendenceData.filter(
        (item) =>
          item.date.slice(0, 15) === previousDate.toString().slice(0, 15) &&
          item.punchOut === undefined
      );

      let filteredData1 = attendenceData.filter(
        (item) =>
          item.date.slice(0, 15) === previousDate.toString().slice(0, 15) &&
          item.punchOut !== undefined
      );
      const timeDiff = new Date() - new Date(filteredData[0]?.date);
      const diffInSeconds = Math.floor(timeDiff / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = diffInMinutes / 60;

      if (diffInHours > 12 && filteredData1.length === 0) {
        setModalVisible1(true);
      } else {
        setModalVisible1(false);
        markAttendence(status);
      }
    }
  };

  const markAttendence = async (status) => {
    setLoading(true);
    const toRadian = (n) => (n * Math.PI) / 180;
    let lat1 = geolocation.latitude;
    let lon1 = geolocation.longitude;
    let lat2 = parseFloat(companyData?.branchLat);
    let lon2 = parseFloat(companyData?.branchLong);
    let x1 = lat2 - lat1;
    let dLat = toRadian(x1);
    let x2 = lon2 - lon1;
    let dLon = toRadian(x2);
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadian(lat1)) *
        Math.cos(toRadian(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let R = 6371; // km
    let d = Math.round(R * c * 3280.84);

    const data = {
      punchIn: time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      lat: lat1,
      long: lon1,
      isTraveling: isTraveling,
      name: localStorage.getItem("userName"),
      date: new Date().toString(),
      empId: localStorage.getItem("empId"),
      companyId: localStorage.getItem("companyId"),
      empBranch: JSON.parse(localStorage.getItem("employeeData"))
        ?.empBranchName,
      empDepartment: JSON.parse(localStorage.getItem("employeeData"))
        ?.empDepartmentName,
      lastStatus: status,
      presentData: "Punchin",
      leaveData: "Punchin",
      type: "self",
    };

    const data1 = {
      punchOut: time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      isTraveling: isTraveling,
      lat: lat1,
      long: lon1,
      name: localStorage.getItem("userName"),
      date:
        modalVisible1 === true
          ? attendenceList[0]?.date
          : new Date().toString(),
      empId: localStorage.getItem("empId"),
      companyId: localStorage.getItem("companyId"),
      empBranch: JSON.parse(localStorage.getItem("employeeData"))
        ?.empBranchName,
      empDepartment: JSON.parse(localStorage.getItem("employeeData"))
        ?.empDepartmentName,
      lastStatus: status,
      presentData: "Punchout",
      leaveData: "Punchout",
      type: "self",
    };

    if (
      (localStorage.getItem("userId") !== undefined &&
        localStorage.getItem("userId") !== null &&
        localStorage.getItem("userId") !== "" &&
        d < parseInt(companyData?.minDistance)) ||
      isTraveling === true
    ) {
      if (lat1 !== null && lon2 !== null) {
        await CreateApi("empattendence", status === "punchIn1" ? data : data1)
          .then((res) => {
            if (res) {
              setLoading(false);
              if (status === "punchIn1") {
                setPunchIn(true);
              } else {
                setPunchIn(false);
              }
              // getEmpAttendence();
              window.location.reload();
            }
          })
          .catch((err) => {
            alert("Something went wrong! Please try again");
            setLoading(false);
          });
      } else {
        alert(
          "Location permission error, Please enable device location and give location permission to App."
        );
      }
    } else {
      alert("You are not on company location");
    }
  };

  useEffect(() => {
    getEmpAttendence();
    getCompanyData();
    getCompanyGeneralData();
  }, []);

  const getEmpAttendence = async () => {
    setLoading(true);
    await GetApi("empattendence", "empId", localStorage.getItem("empId"))
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        let sortedarr = newData.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });

        let punchInData1 = sortedarr?.filter((item) =>
          JSON.stringify(item).includes("punchIn")
        );

        setPunchIndata(punchInData1);

        let punchOutData1 = sortedarr?.filter((item) =>
          JSON.stringify(item).includes("punchOut")
        );

        setPunchOutdata(punchOutData1);

        const uniqueData = sortedarr.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) => t.date?.slice(0, 15) === item?.date.slice(0, 15)
            )
        );

        setAttendenceList(uniqueData);

        setAttenedenceData(sortedarr);

        let todayActivity = uniqueData?.filter(
          (item) =>
            item?.date?.slice(0, 15) === new Date().toString()?.slice(0, 15)
        );

        setTodayActivity(todayActivity);
        if (todayActivity.length > 0) {
          if (todayActivity.some((item) => item.punchIn)) {
            setAlreadyPunchIn(true);
          }
          if (todayActivity.some((item) => item.punchOut)) {
            setAlreadyPunchOut(true);
          } else {
            setAlreadyPunchOut(false);
          }
        }

        if (sortedarr[0]?.lastStatus === "punchIn1") {
          setPunchIn(true);
        } else {
          setPunchIn(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getCompanyData = async () => {
    setLoading(true);
    await getBranchDetail(
      JSON.parse(localStorage.getItem("employeeData")).empBranchId
    )
      .then((res) => {
        setCompanyData(res.data());
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [userIsDesktop, setUserIsDesktop] = useState(true);
  useEffect(() => {
    window.innerWidth < 1280 ? setUserIsDesktop(true) : setUserIsDesktop(false);
  }, [userIsDesktop]);

  const getCompanyGeneralData = async () => {
    await GetApi(
      "generalparameters",
      "companyId",
      localStorage.getItem("companyId")
    )
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setGeneralParameters(newData);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="modal custom-modal fade" id="punchout" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  {/* <h3>{props.Name}</h3> */}
                  <p>
                    Are you sure want to punch out before duty-{" "}
                    {parseInt(generalParameters[0]?.defaultDeutyHrs)} hours ?
                  </p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <Link
                        to="#"
                        className="btn btn-primary continue-btn"
                        data-bs-dismiss="modal"
                        onClick={() => markAttendence("punchOut1")}
                      >
                        {loading === true ? (
                          <div className="spinner-border m-0" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Yes"
                        )}
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                        onClick={() => setLoading(false)}
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal custom-modal fade" id="punchin" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  {/* <h3>{props.Name}</h3> */}
                  <p>
                    You did not punch out yesterday. Please punch out first for
                    yesterday date {previousDate.toString().slice(0, 15)}
                  </p>
                  <label className="col-form-label">
                    Please Select Time <span className="text-danger">*</span>
                  </label>

                  <input
                    type="time"
                    value={selectedTime}
                    className="form-control floating datetimepicker"
                    onChange={(e) => setSelectedTime(e.target.value)}
                  />
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <Link
                        to="#"
                        className="btn btn-primary continue-btn"
                        data-bs-dismiss="modal"
                        onClick={() => markAttendence("punchOut1")}
                      >
                        {loading === true ? (
                          <div className="spinner-border m-0" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Punch Out"
                        )}
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="#"
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                        onClick={() => setLoading(false)}
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="content container-fluid">
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumbs
              maintitle="Attendance"
              title="Dashboard"
              subtitle="Attendance"
            />
            {userIsDesktop === true ? (
              <div className="punch-btn-section">
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={() => window.location.reload()}
                >
                  <i className="la la-refresh" />
                </button>
              </div>
            ) : null}
          </span>

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-4">
              <div className="card punch-status">
                <div className="card-body">
                  <h5 className="card-title">
                    Timesheet{" "}
                    <small className="text-muted">
                      {new Date().toDateString()}
                    </small>
                  </h5>
                  {/* <div className="punch-det">
                    <h6>Punch In at</h6>
                    <p>
                      {punchIndata[punchIndata?.length - 1]?.date?.slice(0, 16)}{" "}
                      {punchIndata[punchIndata?.length - 1]?.punchIn}
                    </p>
                  </div> */}
                  {punchIn === true ? (
                    <div className="punch-info">
                      <div
                        className="punch-hours"
                        style={{ borderColor: "#6FD12F" }}
                      >
                        <span>Active</span>
                      </div>
                    </div>
                  ) : (
                    <div className="punch-info">
                      <div
                        className="punch-hours"
                        style={{ borderColor: "#CF2828" }}
                      >
                        <span>In Active</span>
                      </div>
                    </div>
                  )}

                  <div className="punch-btn-section">
                    <button
                      type="button"
                      className="btn btn-primary punch-btn"
                      onClick={() => handleMarkAttendence("punchIn1")}
                      disabled={alreadyPunchIn === true ? true : false}
                      data-bs-toggle={modalVisible1 === true ? "modal" : ""}
                      data-bs-target={modalVisible1 === true ? "#punchin" : ""}
                    >
                      Punch In
                    </button>
                  </div>

                  <div className="punch-btn-section">
                    <button
                      type="button"
                      className="btn btn-primary punch-btn"
                      onClick={() => handleMarkAttendence("punchOut1")}
                      data-bs-toggle={modalVisible === true ? "modal" : ""}
                      data-bs-target={modalVisible === true ? "#punchout" : ""}
                      disabled={alreadyPunchOut === false ? false : true}
                    >
                      Punch Out
                    </button>
                  </div>
                  <div className="punch-btn-section">
                    <button
                      type="button"
                      className="btn btn-secondary punch-btn"
                      style={{
                        backgroundColor: isTraveling === true ? "green" : "red",
                      }}
                      onClick={() => setIsTraveling(!isTraveling)}
                    >
                      Traveling
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card recent-activity">
                <div className="card-body">
                  <h5 className="card-title">Today Activity</h5>
                  <ul className="res-activity-list">
                    {todayactivity.length > 0 ? (
                      todayactivity?.map((item, i) => (
                        <li key={i}>
                          <p className="mb-0">
                            {item?.lastStatus === "punchIn1"
                              ? "Punch In at"
                              : "Punch Out at"}
                          </p>
                          <p className="res-activity-time">
                            <i className="fa-regular fa-clock"></i>{" "}
                            {item?.lastStatus === "punchIn1"
                              ? item?.punchIn
                              : item?.punchOut}
                          </p>
                        </li>
                      ))
                    ) : (
                      <p>No activities available.</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            {/* <AttendanceEmployeeFilter /> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <Table
                    className="table-striped"
                    columns={columns}
                    size="small"
                    dataSource={attendenceList}
                    rowKey={(record) => record.id}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceEmployee;
