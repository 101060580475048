import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar_02,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
} from "../../../../../Routes/ImagePath";
import { GetApi } from "../../../../../utils/GetApi";
import {
  UpdateEmployeeLeave,
  UpdateUser,
} from "../../../../../utils/UpdateApi";

const LeaveReportTable = () => {
  const data = [
    {
      id: 1,
      image: Avatar_05,
      name: "John Doe",
      number: "#0001",
      date: "20 June 2023",
      department: "Design",
      leavetype: "Sick Leave",
      noofdays: "05",
      remainingleave: "08",
      totalleaves: "20",
      totalleavetaken: "12",
      leavecarryforward: "08",
    },
    {
      id: 2,
      image: Avatar_02,
      name: "Richard Miles",
      number: "#0002",
      date: "20 Dec 2022",
      department: "Android Developer",
      leavetype: "Emergency Leave",
      noofdays: "01",
      remainingleave: "09",
      totalleaves: "20",
      totalleavetaken: "17",
      leavecarryforward: "03",
    },
    {
      id: 3,
      image: Avatar_09,
      name: "Mike Litorous",
      number: "#0003",
      date: "20 Dec 2022",
      department: "IOS Developer",
      leavetype: "Sick Leave",
      noofdays: "15",
      remainingleave: "05",
      totalleaves: "20",
      totalleavetaken: "15",
      leavecarryforward: "05",
    },
    {
      id: 4,
      image: Avatar_11,
      name: "John Smith",
      number: "#0004",
      date: "20 Dec 2022",
      department: "Web Developer",
      leavetype: "Parenting Leave",
      noofdays: "03",
      remainingleave: "15",
      totalleaves: "20",
      totalleavetaken: "15",
      leavecarryforward: "05",
    },

    {
      id: 5,
      image: Avatar_10,
      name: "Wilmer Deluna",
      number: "#0005",
      date: "20 Dec 2022",
      department: "Team Leader",
      leavetype: "Sick Leave",
      noofdays: "10",
      remainingleave: "03",
      totalleaves: "20",
      totalleavetaken: "15",
      leavecarryforward: "05",
    },
  ];

  const columns = [
    {
      title: "Employee",
      dataIndex: "empName",
      render: (text, record) => (
        <div className="table-avatar">
          <Link>
            {text} <span>{record.number}</span>
          </Link>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Applied Date",
      dataIndex: "createdDate",
      // sorter: (a, b) => a.date.length - b.date.length,
      render: (text, record) => record.createdDate?.slice(0, 15),
    },

    {
      title: "Leave From",
      dataIndex: "leaveFrom",
      // sorter: (a, b) => a.date.length - b.date.length,
      render: (text, record) => record.leaveFrom?.slice(0, 15),
    },

    {
      title: "Leave To",
      dataIndex: "leaveTo",
      // sorter: (a, b) => a.date.length - b.date.length,
      render: (text, record) => record.leaveTo?.slice(0, 15),
    },

    // {
    //   title: "Department",
    //   dataIndex: "empDepartment",
    //   // sorter: (a, b) => a.department.length - b.department.length,
    // },
    {
      title: "Leave Type",
      dataIndex: "leaveTypeName",
      render: (text) =>
        // <button
        //   className={`btn btn-outline-${
        //     text === "Emergency Leave"
        //       ? "danger"
        //       : text === "Parenting Leave"
        //       ? "warning"
        //       : "info"
        //   } btn-sm`}
        // >
        //   {text}
        // </button>
        text,
      // sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },
    {
      title: "No.of Days",
      dataIndex: "leaveDays",
      render: (text) => <span className="btn btn-danger btn-sm">{text}</span>,
      // sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Remaining Leave",
      dataIndex: "remainingleave",
      render: (text) => (
        <span className="btn btn-warning btn-sm">
          <b>18</b>
        </span>
      ),
      // sorter: (a, b) => a.remainingleave.length - b.remainingleave.length,
    },
    {
      title: "Total Leaves",
      dataIndex: "totalleaves",
      render: (text) => (
        <span className="btn btn-success btn-sm">
          <b>18</b>
        </span>
      ),
      // sorter: (a, b) => a.totalleaves.length - b.totalleaves.length,
    },

    {
      title: "Status",
      dataIndex: "leaveApproved",
      // sorter: (a, b) => a.totalleavetaken.length - b.totalleavetaken.length,
      render: (text, record) => (
        <div className="dropdown">
          <Link
            to="#"
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            data-bs-toggle= {record.leaveCancel === true || record.leaveApproved === true ? "":"dropdown"}
            aria-expanded="false"
            // aria-disabled={record.leaveCancel === true || record.leaveApproved === true ? true: false}
          >
            <i
              className={
                record.leaveCancel  === false || record.leaveApproved === true
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {record.leaveCancel === true
              ? "Rejected"
              : record.leaveApproved === true
              ? "Approved"
              : "Pending"}
          </Link>
          <div className="dropdown-menu">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleApproved(true, record.id)}
            >
              <i className="far fa-dot-circle text-success" /> Approved
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => handleAReject(true, record.id)}
            >
              <i className="far fa-dot-circle text-danger" /> Reject
            </Link>
          </div>
        </div>
      ),
    },
    // {
    //   title: "Leave Carry Forward",
    //   dataIndex: "leavecarryforward",
    //   sorter: (a, b) => a.leavecarryforward.length - b.leavecarryforward.length,
    // },
  ];

  const [employeeLeaveData, setEmployeeLeaveData] = useState([]);

  useEffect(() => {
    getEmployeeLeaveData();
  }, []);
  const getEmployeeLeaveData = async () => {
    await GetApi("employeeLeaves", "companyId", localStorage.getItem("userId"))
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setEmployeeLeaveData(newData);
      })
      .catch((err) => {});
  };

  const handleApproved = async (status, id) => {
    const data = {
      leaveApproved: status,
      leaveCancel: false,
      updatedDate: new Date().toString(),
      updatedById: localStorage.getItem("userId"),
      updatedByName: localStorage.getItem("userName"),
      updatedByRole: localStorage.getItem("company"),
    };
    await UpdateEmployeeLeave(data, id)
      .then((res) => {
        if (res === true) {
          getEmployeeLeaveData();
        }
      })
      .catch((err) => {});
  };
  const handleAReject = async (status, id) => {
    const data = {
      leaveCancel: status,
      leaveApproved: false,
      updatedById: localStorage.getItem("userId"),
      updatedByName: localStorage.getItem("userName"),
      updatedByRole: localStorage.getItem("company"),
      updatedDate: new Date().toString(),
    };
    await UpdateEmployeeLeave(data, id)
      .then((res) => {
        if (res === true) {
          getEmployeeLeaveData();
        }
      })
      .catch((err) => {});
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={employeeLeaveData}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaveReportTable;
