/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useLocation } from "react-router-dom";
import { UpdateApi } from "../../../utils/UpdateApi";
import { GetUser } from "../../../utils/GetApi";

const Profile = () => {
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [editPassword, setEditPassword] = useState(false);
  const [empLoginEnable, setEmpLoginEnable] = useState(true);
  const [data, setData] = useState({});

  const handleUpdatePassword = async () => {
    const data1 = {
      password: password,
    };
    await UpdateApi("employee", data1, data?.id).then(async (res) => {
      if (res === true) {
        setEditPassword(false);
        await GetUser(data?.empId, password, empLoginEnable).then((res) => {
          if (res) {
            const newData = res.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            if (newData.length > 0 && newData[0].isActive === true) {
              if (newData[0].role === "employee") {
                localStorage.setItem(
                  "employeeData",
                  JSON.stringify(newData[0])
                );
              }
            }
          }
        });
        alert("Password changed successfully");
      } else {
        alert("Something went wrong");
      }
    });
  };

  useEffect(() => {
    setPassword(location?.state?.password);
    setData(location?.state);
  }, []);

  console.log("location", data);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <img src={data?.empImage} alt="User Image" />
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {data?.name}
                            </h3>
                            <h6 className="text-muted">
                              {data?.empDesignationName}
                            </h6>
                            <small className="text-muted">
                              {data?.empDepartmentName}
                            </small>
                            <div className="staff-id">
                              Employee ID : {data?.empId}
                            </div>
                            <div className="small doj text-muted">
                              Date of Join : {data?.empJoinDate?.slice(0, 16)}
                            </div>
                            <div className="small doj text-muted">
                              Reference Number : {data?.refernceNumber}
                            </div>
                            <div className="small doj text-muted">
                              Password :{" "}
                              {editPassword === true ? (
                                <>
                                  <input
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                  {/* <i
                                    className="la la-x"
                                    onClick={() => setEditPassword(false)}
                                  /> */}
                                  <button
                                    onClick={() => setEditPassword(false)}
                                  >
                                    <span aria-hidden="true">×</span>
                                  </button>
                                  <button
                                    onClick={() => handleUpdatePassword()}
                                  >
                                    <span aria-hidden="true">Update</span>
                                  </button>
                                </>
                              ) : (
                                <>
                                  {password}
                                  <i
                                    className="la la-pencil"
                                    onClick={() => setEditPassword(true)}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">{data?.phone}</div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">{data?.email}</div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">
                                {data?.dob?.slice(0, 16)}
                              </div>
                            </li>
                            <li>
                              <div className="title">Address:</div>
                              <div className="text">
                                {data?.presentAddress +
                                  ", " +
                                  data?.presentDistrict +
                                  ", " +
                                  data?.presentCity +
                                  ", " +
                                  data?.presentState +
                                  ", " +
                                  data?.presentPincode}
                              </div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">{data?.empGender}</div>
                            </li>
                            <li>
                              <div className="title">Company:</div>
                              <div className="text">
                                <div className="avatar-box"></div>

                                {data?.empCompanyName}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Profile Info Tab */}
          <ProfileTab data={data} />
        </div>
      </div>
    </>
  );
};

export default Profile;
