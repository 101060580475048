import React, { useEffect, useRef, useState } from "react";

import { Table } from "antd";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { GetApi, getCompanyData } from "../../../utils/GetApi";

import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import DatePicker from "react-datepicker";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
    color: state.isFocused ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#ff9b44",
    },
  }),
};

const AttendenceReport = () => {
  const [empList, setEmpList] = useState([]);
  const [empList1, setEmpList1] = useState([]);

  const [attendenceData, setAttendenceData] = useState([]);
  const [generalParameters, setGeneralParameters] = useState([]);
  const [searchDataDepartment, setSearchdataDepartment] = useState("");
  const [selectedDate1, setSelectedDate1] = useState(new Date());
  const [branchList, setBranchList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [searchDataBranch, setSearchdataBranch] = useState("");

  useEffect(() => {
    getEmployeeList();
  }, []);

  useEffect(() => {
    getCompanyGeneralData();
    getBranchList();
    getDepartmentList();
  }, []);

  const getBranchList = async () => {
    await GetApi("branch", "createdBy", localStorage.getItem("userId"))
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        newData.forEach((object) => {
          object.value = object.id;
          object.label = object.branchName;
        });
        setBranchList(newData);
      })
      .catch((err) => {});
  };
  const getDepartmentList = async () => {
    await GetApi("departments", "createdBy", localStorage.getItem("userId"))
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        newData.forEach((object) => {
          object.value = object.id;
          object.label = object.departmentName;
        });
        setDepartmentList(newData);
      })
      .catch((err) => {});
  };

  const getEmployeeList = async () => {
    await GetApi(
      "empattendence",
      "companyId",
      localStorage.getItem("userId")
    ).then((res) => {
      const newData = res.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      let sortedarr = newData.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });

      let sortedarrFilter = sortedarr?.filter(
        (item) => new Date(item?.date).getMonth() === new Date().getMonth()
      );

      setAttendenceData(sortedarrFilter);

      const uniqueData = sortedarrFilter.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.date.slice(0, 15) === item?.date.slice(0, 15) &&
              t.empId === item.empId
          )
      );

      setEmpList(uniqueData);
      setEmpList1(sortedarr);
    });
  };

  const getCompanyGeneralData = async () => {
    await GetApi(
      "generalparameters",
      "companyId",
      localStorage.getItem("userId")
    )
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setGeneralParameters(newData);
      })
      .catch((err) => {});
  };

  const handleFilterEmpList = () => {
    if (searchDataDepartment !== "") {
      const list = empList.filter(
        (item) =>
          item.empDepartment?.toLowerCase() ===
          searchDataDepartment?.toLowerCase()
      );
      setEmpList(list);
    }
    if (searchDataBranch !== "") {
      const list = empList.filter(
        (item) =>
          item.empBranch?.toLowerCase() === searchDataBranch?.toLowerCase()
      );
      setEmpList(list);
    }
    if (selectedDate1 !== "") {
      const list = empList1.filter(
        (item) => new Date(item?.date).getMonth() === selectedDate1?.getMonth()
      );
      setEmpList(list);
    }
    if (selectedDate1 !== "" && searchDataDepartment !== "") {
      const list = empList.filter(
        (item) =>
          item.empDepartment?.toLowerCase() ===
            searchDataDepartment?.toLowerCase() &&
          item.date?.slice(0, 15) === selectedDate1?.toString().slice(0, 15)
      );
      setEmpList(list);
    }
    if (selectedDate1 !== "" && searchDataBranch !== "") {
      const list = empList.filter(
        (item) =>
          item.empBranch?.toLowerCase() === searchDataBranch?.toLowerCase() &&
          item.date?.slice(0, 15) === selectedDate1?.toString().slice(0, 15)
      );
      setEmpList(list);
    }
    if (searchDataBranch !== "" && searchDataDepartment !== "") {
      const list = empList.filter(
        (item) =>
          item.empDepartment?.toLowerCase() ===
            searchDataDepartment?.toLowerCase() &&
          item.empBranch?.toLowerCase() === searchDataBranch?.toLowerCase()
      );
      setEmpList(list);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      width: "2%",
      render: (text, record, i) => <p style={{ fontSize: 10 }}>{i + 1}</p>,
    },

    {
      title: "E-Code",
      dataIndex: "empId",
      width: "5%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => <p style={{ fontSize: 10 }}>{text}</p>,
    },

    {
      title: "Employee Name",
      dataIndex: "name",
      width: "10%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => <p style={{ fontSize: 10 }}>{text}</p>,
    },
    {
      title: "Head",
      dataIndex: "",
      width: "5%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => (
        <span style={{ fontSize: 10 }}>
          <p>Date</p>
          <p>Att</p>
          <p>Hrs</p>
          <p>Travel</p>
          <p>Approved</p>
        </span>
      ),
    },
    {
      title: `Date (${selectedDate1.toString().slice(4, 8)})`,
      dataIndex: "date",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      className: "specific-table-column",
      render: (text, record, i) => {
        const filter1 = empList?.filter(
          (item) => item?.empId === record?.empId
        );
        let punchIn = attendenceData
          ?.filter(
            (item) =>
              item?.punchIn &&
              item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
              item?.empId === record?.empId
          )
          .map((it) => it?.date);

        let punchOut = attendenceData
          ?.filter(
            (item) =>
              item?.punchOut &&
              item?.date?.slice(0, 15) === record?.date?.slice(0, 15) &&
              item?.empId === record?.empId
          )
          .map((it) => it?.date);
        const timeDifferences = punchIn?.map((obj1) => {
          const obj2 = punchOut?.find((obj) => obj);
          if (obj2) {
            const timeDiff = new Date(obj2) - new Date(obj1);
            const diffInSeconds = Math.floor(timeDiff / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = diffInMinutes / 60;
            return { timeDifference: diffInHours };
          }
          return null;
        });

        return filter1?.map((it, i) => (
          <span key={i} style={{ fontSize: 10 }}>
            <p style={{ paddingRight: 20 }}>{it?.date?.slice(7, 11)}</p>
            <p>
              {timeDifferences[timeDifferences?.length - 1] !== null &&
              timeDifferences[timeDifferences?.length - 1]?.timeDifference !==
                undefined
                ? timeDifferences[timeDifferences?.length - 1]?.timeDifference >
                  parseInt(generalParameters[0]?.defaultDeutyHrs) -
                    parseInt(generalParameters[0]?.grasePreiodDuety) / 60
                  ? "P"
                  : "A"
                : "NA"}
            </p>
            <p>
              {timeDifferences[timeDifferences?.length - 1] !== null &&
              timeDifferences[timeDifferences?.length - 1]?.timeDifference !==
                undefined
                ? `${Math.abs(
                    timeDifferences[
                      timeDifferences?.length - 1
                    ]?.timeDifference?.toFixed(2)
                  )}`
                : "NA"}
            </p>
            <p>{record?.isTraveling === true ? "Yes" : "No"}</p>
            {i === 0 ? <p>101</p> : ""}
          </span>
        ));
      },
    },
    {
      title: "Total",
      dataIndex: "name",
      width: "8%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => (
        <span style={{ fontSize: 10 }}>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>Pay Day</p>
            <p>LOP Day</p>
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <p>10</p>
            <p>01</p>
          </span>

          <p style={{ textAlign: "center" }}>75.55</p>
          <p style={{ textAlign: "center" }}>02</p>
          <p style={{ textAlign: "center" }}>101</p>
        </span>
      ),
    },
  ];

  const componentRef = useRef();
  const [printSalary, setPrintSalary] = useState(false);

  useEffect(() => {
    if (printSalary === true) {
      handlePrint();
    }

    if (printSalary === true) {
      setPrintSalary(false);
    }
  }, [printSalary]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(empList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(
      dataBlob,
      `${localStorage.getItem(
        "userName"
      )}-employeeattendencedata--${selectedDate1
        ?.toString()
        .slice(4, 8)} ${selectedDate1?.getFullYear()}.xlsx`
    );
  };

  const handleDownloadSheet = async () => {
    await getCompanyData("users", localStorage.getItem("userId")).then(
      (res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        if (res !== null && newData?.length > 0) {
          console.log("newData", newData);
          if (
            newData[0]?.attendenceSheet !== undefined &&
            newData[0]?.attendenceSheet !== null &&
            newData[0]?.attendenceSheet !== ""
          ) {
            window.open(newData[0]?.attendenceSheet);
          }
        }
      }
    );
  };

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee Attendence Report"
            title="Dashboard"
            subtitle="Employee Attendence Report"
            printSalary={printSalary}
            setPrintSalary={setPrintSalary}
          />
          {/* /Page Header */}
          <div className="row filter-row">
            <div className="col-sm-3">
              <div className="input-block mb-3">
                <Select
                  options={departmentList}
                  placeholder={"Select Department"}
                  styles={customStyles}
                  onChange={(e) => setSearchdataDepartment(e.value)}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="input-block mb-3">
                <Select
                  options={branchList}
                  placeholder={"Select Branch"}
                  styles={customStyles}
                  onChange={(e) => setSearchdataBranch(e.value)}
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="cal-icon">
                <DatePicker
                  selected={selectedDate1}
                  onChange={handleDateChange1}
                  className="form-control floating datetimepicker"
                  type="date"
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                />
              </div>
            </div>

            <div className="col-sm-2">
              <button
                className="btn btn-primary "
                aria-label="Close"
                onClick={() => handleFilterEmpList()}
              >
                Filter
              </button>
            </div>

            <div className="col-sm-1">
              <button
                className="btn btn-primary "
                aria-label="Close"
                onClick={() => handleDownloadSheet()}
              >
                Export
              </button>
            </div>
          </div>
          <div className="row" ref={componentRef}>
            <h3 style={{ textAlign: "center", marginTop: 20 }}>
              {localStorage.getItem("userName")}
            </h3>
            <h4 style={{ textAlign: "center" }}>
              Attendance Report for the Month of{" "}
              {new Date().toString().slice(4, 7)}{" "}
              {new Date().toString().slice(10, 15)}
            </h4>

            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  size="small"
                  dataSource={empList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};

export default AttendenceReport;
