import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

const EmployeeAttendenceFilter = ({ setSearchdata }) => {
  const [empId, setEmpId] = useState("");
  const [empName, setEmpName] = useState("");
  const [empDate, setEmpDate] = useState("");

  const handleSearch = () => {
    if (empId !== "" || empName !== "" || empDate !== "") {
      let data = {
        empId: empId,
        empName: empName,
        empDate: empDate.toString(),
      };
      setSearchdata(data);
    } else {
      // setSearchdata({});
    }
  };

  const handleClear = () => {
    setSearchdata({});
    setEmpId("");
    setEmpName("");
    setEmpDate("");
  };

  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3">
          <div
            className={
              empId
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={empId}
              onChange={(e) => setEmpId(e.target.value)}
            />
            <label className="focus-label">Employee ID</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div
            className={
              empName
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={empName}
              onChange={(e) => setEmpName(e.target.value)}
            />
            <label className="focus-label">Employee Name</label>
          </div>
        </div>

        <div className="col-sm-6 col-md-3">
          <div
            className={
              empDate
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            {/* <input
              type="text"
              className="form-control floating"
              value={empDate}
              onChange={(e) => setEmpDate(e.target.value)}
            /> */}
            <div className="cal-icon">
              <DatePicker
                selected={empDate}
                required
                onChange={(date) => setEmpDate(date)}
                className="form-control floating datetimepicker"
                type="date"
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <label className="focus-label">Date</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-1">
          <Link
            to="#"
            className="btn btn-success btn-block w-100"
            onClick={handleSearch}
          >
            {" "}
            Search{" "}
          </Link>
        </div>
        {empId !== "" || empName !== "" || empDate !== "" ? (
          <div className="col-sm-6 col-md-1">
            <Link
              to="#"
              className="btn btn-success btn-block w-100"
              onClick={handleClear}
            >
              {" "}
              Clear{" "}
            </Link>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EmployeeAttendenceFilter;
