import React, { useEffect, useRef, useState } from "react";

import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { GetApi } from "../../../utils/GetApi";
import { CreateApi } from "../../../utils/PostApi";
import { UpdateApi } from "../../../utils/UpdateApi";

const LeaveDataAdd = () => {
  const [empList, setEmpList] = useState([]);
  const [leaveParameters, setLeaveParameters] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [newData, setNewdata] = useState([]);

  useEffect(() => {
    getEmployeeList();
    getLeaveList();
    getEmpLeaveData();
  }, []);

  const getEmployeeList = async () => {
    await GetApi(
      "employee",
      "empCompanyId",
      localStorage.getItem("userId")
    ).then((res) => {
      const newData = res.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      let sortedarr = newData.sort(function (a, b) {
        return a.empId - b.empId;
      });

      setEmpList(sortedarr);
    });
  };

  const getLeaveList = async () => {
    await GetApi("leaveparameters", "companyId", localStorage.getItem("userId"))
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setLeaveParameters(newData);
      })
      .catch((err) => {});
  };

  const getEmpLeaveData = async () => {
    await GetApi("empleavedata", "companyId", localStorage.getItem("userId"))
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log("newData", newData);
        if (newData.length > 0) {
          setLeaveData(newData[0]?.leaveData);
          setNewdata(newData);
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      width: "2%",
      render: (text, record, i) => <p>{i + 1}</p>,
    },

    {
      title: "Emp Code",
      dataIndex: "empId",
      width: "5%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => <p>{text}</p>,
    },

    {
      title: "Emp Name",
      dataIndex: "name",
      width: "10%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => <p>{text}</p>,
    },
    {
      title: "Department",
      dataIndex: "empDepartmentName",
      width: "10%",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => <p>{text}</p>,
    },

    {
      title: "Opening Balance",
      dataIndex: "name",
      width: "80%",
      align: "center",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record, i) => (
        <span
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {leaveParameters?.map((item, ii) => (
            <div className="input-block mb-3" key={ii} style={{ margin: 2 }}>
              <label className="col-form-label">{item?.leaveShortName}</label>
              <input
                className="form-control"
                type="text"
                name={item?.leaveShortName}
                id={record?.empId}
                value={leaveData
                  ?.filter(
                    (it) =>
                      it?.leaveName === item?.leaveShortName &&
                      it?.empId === record?.empId
                  )
                  ?.map((itt) => itt?.leaveValue)}
                onChange={(e) =>
                  handleChangeLeaveData(
                    e?.target?.name,
                    e?.target?.id,
                    e?.target?.value
                  )
                }
              />
            </div>
          ))}
        </span>
      ),
    },
  ];

  const handleChangeLeaveData = (name, id, value) => {
    let f = leaveData?.filter((d) => d.empId === id && d.leaveName === name);

    if (f.length > 0) {
      let list = [...f];
      list[0]["leaveValue"] = value;
      let ff = leaveData?.concat(list);
      const uniqueData = ff.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) => t.empId === item.empId && t.leaveName === item.leaveName
          )
      );
      setLeaveData(uniqueData);
    } else {
      let list = [
        ...leaveData,
        {
          leaveName: name,
          empId: id,
          leaveValue: value,
        },
      ];
      setLeaveData(list);
    }
  };

  const handleCreateLeaveData = async () => {
    const data = {
      leaveData: leaveData,
      companyId: localStorage.getItem("userId"),
      createdDate: new Date().toString(),
    };
    if (
      newData[0]?.companyId !== undefined &&
      newData[0]?.companyId == localStorage.getItem("userId")
    ) {
      await UpdateApi("empleavedata", data, newData[0]?.id)
        .then((res) => {
          if (res) {
            window.location.reload();
          }
        })
        .catch((err) => {});
    } else {
      await CreateApi("empleavedata", data)
        .then((res) => {
          if (res) {
            window.location.reload();
          }
        })
        .catch((err) => {});
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employee Leaves"
            title="Dashboard"
            subtitle="Employee Leaves"
          />
          {/* /Page Header */}
          <div
            className="row filter-row"
            style={{ justifyContent: "flex-end" }}
          >
            <div className="col-sm-1">
              <button
                className="btn btn-primary "
                aria-label="Close"
                onClick={handleCreateLeaveData}
              >
                Save
              </button>
            </div>
          </div>
          <div className="row">
            <h3 style={{ textAlign: "center", marginTop: 20 }}>
              {localStorage.getItem("userName")}
            </h3>
            <h4 style={{ textAlign: "center" }}>
              Leave Opening Balance As On Date-{" "}
              {new Date().toString().slice(0, 10)}
            </h4>

            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Table
                  className="table-striped"
                  columns={columns}
                  size="small"
                  dataSource={empList}
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};

export default LeaveDataAdd;
