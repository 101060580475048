import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetApi } from "../../utils/GetApi";
import { CreateApi } from "../../utils/PostApi";

const EmployeeLeaveModelPopup = () => {
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [leaveData, setLeaveData] = useState([]);

  const [leaveTypeId, setLeaveTypeId] = useState("");
  const [leaveTypeName, setLeaveTypeName] = useState("");
  const [leaveDays, setLeaveDays] = useState(0);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
    setSelectedDate2(null)
  };
  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
    const ONE_DAY = 1000 * 60 * 60 * 24;

    const differenceMs = Math.abs(selectedDate1 - date);

    setLeaveDays(Math.round(differenceMs / ONE_DAY));
  };

  const leavetype = [
    { value: 1, label: "Select Leave Type" },
    { value: 2, label: "Casual Leave 12 days" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  useEffect(() => {
    getCompanyLeaveData();
  }, []);

  const getCompanyLeaveData = async () => {
    await GetApi(
      "leaveparameters",
      "companyId",
      localStorage.getItem("companyId")
    )
      .then((res) => {
        const newData = res.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        newData.forEach((object) => {
          object.value = object.id;
          object.label = object.leaveShortName;
        });
        // console.log("newdata", newData);

        setLeaveData(newData);
      })
      .catch((err) => {});
  };

  const handleChangeLeaveType = (e) => {
    setLeaveTypeId(e.value);
    setLeaveTypeName(e.label);
  };

  const handleCreateLeave = async (e) => {
    e.preventDefault();
    if (
      localStorage.getItem("userId") !== undefined &&
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== "" &&
      localStorage.getItem("userRole") !== undefined &&
      localStorage.getItem("userRole") !== null &&
      localStorage.getItem("userRole") !== ""
    ) {
      setLoading(true);
      const data = {
        leaveTypeName: leaveTypeName,
        leaveTypeId: leaveTypeId,
        leaveFrom: selectedDate1.toString(),
        leaveTo: selectedDate2.toString(),
        leaveDays: leaveDays,
        leaveReason: reason,
        leaveApproved: false,
        leaveApprovedBy: "",
        leaveApprovedDate: "",
        leaveCancelReason: "",
        leaveCancelBy: "",
        leaveCancelDate: "",
        empName: localStorage.getItem("userName"),
        empDepartment: JSON.parse(localStorage.getItem("employeeData"))
          .empDepartmentName,
        empBranch: JSON.parse(localStorage.getItem("employeeData"))
          .empBranchName,
        leaveCancel: false,
        companyId: localStorage.getItem("companyId"),
        createdBy: localStorage.getItem("userId"),
        createdUserRole: localStorage.getItem("userRole"),
        createdDate: new Date().toString(),
      };
      await CreateApi("employeeLeaves", data)
        .then((res) => {
          if (res) {
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
    }
  };

  return (
    <>
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleCreateLeave}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leaveData}
                    placeholder="Select"
                    required
                    styles={customStyles}
                    onChange={(e) => handleChangeLeaveType(e)}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate1}
                      onChange={handleDateChange1}
                      className="form-control datetimepicker"
                      type="date"
                      required
                      minDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      required
                      minDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={leaveDays}
                    onChange={() => {}}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    required
                    onChange={(e) => setReason(e.target.value)}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    // data-bs-dismiss="modal"
                    aria-label="Close"
                    type="submit"
                    // onClick={handleCreateLeave}
                  >
                    {loading === true ? (
                      <div className="spinner-border m-0" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="edit_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leavetype}
                    placeholder="Select"
                    styles={customStyles}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <div className="cal-icon">
                    <DatePicker
                      selected={selectedDate2}
                      onChange={handleDateChange2}
                      className="form-control datetimepicker"
                      type="date"
                      dateFormat="dd-MM-yyyy"
                    />
                  </div>
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    type="text"
                    defaultValue={2}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    readOnly
                    defaultValue={12}
                    type="text"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    defaultValue={"Going to hospital"}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeLeaveModelPopup;
